.purchase-now-section {
    background-color: #FFF;
    padding: 50px 50px 50px 50px;
}

.purchase-now-title-container {
    margin-bottom: 5px;
    padding: 0px 0px 20px 0px;
}
  
.purchase-now-title {
    color: #E74C38;
    line-height: 1.3em;
    padding: 0;
    margin: 0;
    font-weight: 600;
}
  
.purchase-now-subtitle-container {
    margin-bottom: 5px;
    padding: 0px 0px 12px 0px;
}
  
.purchase-now-img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 50%;
    box-shadow: none;
}
  
.purchase-now-container {
    display: flex;
    justify-content: center;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
}


.purchase-now-legend-container {
    padding: 10px 0 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}
  
.purchase-now-img-container {
    width: 90%;
    padding: 10px 0 10px 0;
}
  
.purchase-now-subtitle {
    font-weight: 600;
    font-size: 1rem;
}

@media (min-width: 768px) { 
    .purchase-now-container {
        flex-direction: unset;
    }

    .purchase-now-legend-container {
        width: 50%;
    }
  
    .purchase-now-img-container {
        width: 20%;
    }
  
    .purchase-now-subtitle {
        font-size: inherit;
    }
}
  