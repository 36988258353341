.supplement-container {
    display: flex;
    flex-wrap: wrap;
}

.supplement-table-cell {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #EDEDED;
    margin: 5px 5px 5px 5px;
    padding: 10px;
    display: flex;
    align-content: center;
    background-color: #FFF;
    text-align: left;
    flex-direction: column;
    width: 100%;
} 

.supplement-title-container {
    margin-bottom: 5px;
    padding: 0px 0px 8px 0px;
}
  
.supplement-title {
    line-height: 1.2em;
    color: #0C233F;
    padding: 0;
    margin: 0;
}
  
.supplement-description-container {
    color: #444444;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}  

.supplement-dosage-title-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}

.supplement-dosage-title {
    color: #0C233F;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-weight: 600;
}
  
.supplement-dosage-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}

.supplement-dosage,
.supplement-schedule {
    color: #444444;
    margin-bottom: 5px;
}
  
.supplement-schedule-title-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}
  
.supplement-schedule-title {
    color: #0C233F;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-weight: 600;
}
  
.supplement-schedule-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}
  
.supplement-discounted-price-container{
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}
  
.supplement-discounted-price {
    padding: 0;
    margin: 0;
    line-height: 1.5em;
    color: #E74C38;
    font-weight: 600;
}

.supplement-real-price-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}

.supplement-real-price {
    padding: 0;
    margin: 0;
    line-height: 1.5em;
    color: #7A7A7A;
    font-weight: 600;
    text-decoration-line: line-through;
    text-decoration-color: #7A7A7A;
}
  
.supplement-details-container, 
.supplement-img-container,
.supplement-scheduling-container,
.supplement-price-container {
    width: 100%;
    display: flex;
}  

.supplement-img {
    height: auto;
    max-width: 60%;
    margin: auto;
} 

.supplement-price-container {
    text-align: right !important;
}

@media (min-width: 768px) { 
    .supplement-img-container {
        width: 20%;
        display: flex;
    }  

    .supplement-img {
        max-width: 100%;
    }      
    
    .supplement-details-container {
        width: 43%;  
        display: flex;
    }

    .supplement-scheduling-container {
        display: flex;
        width: 25%;
    }
  
    .supplement-price-container {
        display: flex;
        width: 10%;
    }
}