.loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(220, 220, 220, 0.7);
    z-index: 1;
    overflow: hidden;
}
