@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500');

.App {
  text-align: center;
  font-family: "Montserrat" , Sans-serif;
}

.App-loading {
  overflow: hidden;
  height: 100vh;
}



h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

h1, h2, h5, h6 {
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: .9rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

@media (min-width: 768px) { 
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }
}