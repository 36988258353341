.subtitle-section {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    background-image: linear-gradient(160deg, #D3E6FF 0%, #D1F0D5 100%);
    padding: 47px 0 35px 0;
}
  
.subtitle-container {
    padding: 10px 0 10px 0;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}
   
.secondary-subtitle-container {
    margin-bottom: 5px;
}

.secondary-subtitle {
    text-align: center;
    color: #444444;
    font-weight: 600;
}

.order-now-btn-section {
    padding: 3px 0px 0px 0px;
}
  
.order-now-btn-container {
    padding: 10px 0 10px 0;
}

.main-subtitle {  
    margin: 0 0 5px 0;
    line-height: 1.2em;
    color: #0C233F;
    font-size: 1.5rem;
}    

@media (min-width: 768px) { 
    .main-subtitle {  
        margin: 0 100px 5px 100px;
        font-size: 2rem;
    }     
}