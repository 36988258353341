.legend-section {
    display: flex;
    justify-content: center;
    background-color: #F3F3F3;
    padding: 30px 0px 20px 0px;
}
  
.legend-container {
    padding: 10px;
    text-align: left;
    max-width: 1140px;
}
  
.legend {
    font-weight: 450;
    color: #444444;
    font-size: 13px;
    line-height: 1.5;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) { 
    .legend {
        width: 70%;
    }
}