.recommendation-section {
    background-color: transparent;
    background-image: linear-gradient(200deg, #FFFDBF 0%, #FFD3D3 100%);
    padding: 37px 0 60px 0;
}
  
.recommendation-container {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}
  
.recommendation-title {
    color: #E74C38;
    padding: 0;
    margin: 0;
    line-height: 1;
}
  
.recommendation-order-now-btn-section {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 27px 0px 0px 0px;
}

.recommendation-title-container {
    padding: 0px 0px 30px 5px;
    margin-bottom: 5px;
    text-align: center;
}

@media (min-width: 768px) { 
    .recommendation-title-container {
        text-align: left;
    }
}

  
