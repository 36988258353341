.order-now-btn {
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 20px 20px 20px 20px;
    background-color: #E74C38;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    color: #FFF;
    fill: #fff;
    text-align: center;
    display: inline-block;
    line-height: 1;
    font-size: 15px;
    padding: 12px 24px;
    box-shadow: none;
    text-decoration: none;
    font-weight: 600;
}  