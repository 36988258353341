@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: "Montserrat" , Sans-serif;
}

.App-loading {
  overflow: hidden;
  height: 100vh;
}



h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

h1, h2, h5, h6 {
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: .9rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

@media (min-width: 768px) { 
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }
}
.header-img {
    background-image: url("https://wellworld.io/wp-content/uploads/2022/10/doc.nutrition.primalmulti-scaled.jpg");
    background-position: center center;
    background-size: cover;
    height: 400px;
}
.title-section {
    background-color: #e74c38;
    padding: 52px 0 52px 0;
    color: #FFF;
    line-height: 1;
}
  
.title {
    margin: 0;
}
.order-now-btn {
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 20px 20px 20px 20px;
    background-color: #E74C38;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    color: #FFF;
    fill: #fff;
    text-align: center;
    display: inline-block;
    line-height: 1;
    font-size: 15px;
    padding: 12px 24px;
    box-shadow: none;
    text-decoration: none;
    font-weight: 600;
}  
.subtitle-section {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    background-image: linear-gradient(160deg, #D3E6FF 0%, #D1F0D5 100%);
    padding: 47px 0 35px 0;
}
  
.subtitle-container {
    padding: 10px 0 10px 0;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}
   
.secondary-subtitle-container {
    margin-bottom: 5px;
}

.secondary-subtitle {
    text-align: center;
    color: #444444;
    font-weight: 600;
}

.order-now-btn-section {
    padding: 3px 0px 0px 0px;
}
  
.order-now-btn-container {
    padding: 10px 0 10px 0;
}

.main-subtitle {  
    margin: 0 0 5px 0;
    line-height: 1.2em;
    color: #0C233F;
    font-size: 1.5rem;
}    

@media (min-width: 768px) { 
    .main-subtitle {  
        margin: 0 100px 5px 100px;
        font-size: 2rem;
    }     
}
.supplement-container {
    display: flex;
    flex-wrap: wrap;
}

.supplement-table-cell {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #EDEDED;
    margin: 5px 5px 5px 5px;
    padding: 10px;
    display: flex;
    align-content: center;
    background-color: #FFF;
    text-align: left;
    flex-direction: column;
    width: 100%;
} 

.supplement-title-container {
    margin-bottom: 5px;
    padding: 0px 0px 8px 0px;
}
  
.supplement-title {
    line-height: 1.2em;
    color: #0C233F;
    padding: 0;
    margin: 0;
}
  
.supplement-description-container {
    color: #444444;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}  

.supplement-dosage-title-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}

.supplement-dosage-title {
    color: #0C233F;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-weight: 600;
}
  
.supplement-dosage-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}

.supplement-dosage,
.supplement-schedule {
    color: #444444;
    margin-bottom: 5px;
}
  
.supplement-schedule-title-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}
  
.supplement-schedule-title {
    color: #0C233F;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-weight: 600;
}
  
.supplement-schedule-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}
  
.supplement-discounted-price-container{
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}
  
.supplement-discounted-price {
    padding: 0;
    margin: 0;
    line-height: 1.5em;
    color: #E74C38;
    font-weight: 600;
}

.supplement-real-price-container {
    padding: 0px 0px 8px 0px;
    margin-bottom: 5px;
}

.supplement-real-price {
    padding: 0;
    margin: 0;
    line-height: 1.5em;
    color: #7A7A7A;
    font-weight: 600;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    -webkit-text-decoration-color: #7A7A7A;
            text-decoration-color: #7A7A7A;
}
  
.supplement-details-container, 
.supplement-img-container,
.supplement-scheduling-container,
.supplement-price-container {
    width: 100%;
    display: flex;
}  

.supplement-img {
    height: auto;
    max-width: 60%;
    margin: auto;
} 

.supplement-price-container {
    text-align: right !important;
}

@media (min-width: 768px) { 
    .supplement-img-container {
        width: 20%;
        display: flex;
    }  

    .supplement-img {
        max-width: 100%;
    }      
    
    .supplement-details-container {
        width: 43%;  
        display: flex;
    }

    .supplement-scheduling-container {
        display: flex;
        width: 25%;
    }
  
    .supplement-price-container {
        display: flex;
        width: 10%;
    }
}
.recommendation-section {
    background-color: transparent;
    background-image: linear-gradient(200deg, #FFFDBF 0%, #FFD3D3 100%);
    padding: 37px 0 60px 0;
}
  
.recommendation-container {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}
  
.recommendation-title {
    color: #E74C38;
    padding: 0;
    margin: 0;
    line-height: 1;
}
  
.recommendation-order-now-btn-section {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 27px 0px 0px 0px;
}

.recommendation-title-container {
    padding: 0px 0px 30px 5px;
    margin-bottom: 5px;
    text-align: center;
}

@media (min-width: 768px) { 
    .recommendation-title-container {
        text-align: left;
    }
}

  

.purchase-now-section {
    background-color: #FFF;
    padding: 50px 50px 50px 50px;
}

.purchase-now-title-container {
    margin-bottom: 5px;
    padding: 0px 0px 20px 0px;
}
  
.purchase-now-title {
    color: #E74C38;
    line-height: 1.3em;
    padding: 0;
    margin: 0;
    font-weight: 600;
}
  
.purchase-now-subtitle-container {
    margin-bottom: 5px;
    padding: 0px 0px 12px 0px;
}
  
.purchase-now-img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 50%;
    box-shadow: none;
}
  
.purchase-now-container {
    display: flex;
    justify-content: center;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
}


.purchase-now-legend-container {
    padding: 10px 0 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}
  
.purchase-now-img-container {
    width: 90%;
    padding: 10px 0 10px 0;
}
  
.purchase-now-subtitle {
    font-weight: 600;
    font-size: 1rem;
}

@media (min-width: 768px) { 
    .purchase-now-container {
        flex-direction: unset;
    }

    .purchase-now-legend-container {
        width: 50%;
    }
  
    .purchase-now-img-container {
        width: 20%;
    }
  
    .purchase-now-subtitle {
        font-size: inherit;
    }
}
  
.legend-section {
    display: flex;
    justify-content: center;
    background-color: #F3F3F3;
    padding: 30px 0px 20px 0px;
}
  
.legend-container {
    padding: 10px;
    text-align: left;
    max-width: 1140px;
}
  
.legend {
    font-weight: 450;
    color: #444444;
    font-size: 13px;
    line-height: 1.5;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) { 
    .legend {
        width: 70%;
    }
}
.footer-section {
    background-color: #112947;
    padding: 60px 0px 30px 0px;
}
  
.footer-container {
    margin: 30px;
}
  
.footer-img-container {
    margin-bottom: 5px;
}

.tos span a {
    color: #E74C38;
    text-decoration: none;
}
  
.separator {
    margin: 0 5px 0 5px;
}
  
.email a {
    color: #FFF;
    text-decoration: none;
    vertical-align: middle;
}
  
.mail-icon {
    margin-right: 5px;
    vertical-align: middle;
}

.footer-img {
    width: 100%;
}
  
.footer-tos-container {
    color: #FFF;
    font-size: 15px;
    font-weight: 450;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
}
  
.trademark-container,
.tos-container,
.email-container {
    width: 100%;
    padding: 10px;
    text-align: center;
}

@media (min-width: 768px) { 
    .footer-img {
        width: inherit;
    }

    .footer-tos-container {
        flex-direction: unset;
    }

    .trademark-container,
    .tos-container,
    .email-container {
        width: 33.33%;
    }

    .trademark-container {
        text-align: right;
    }

    .email-container {
        text-align: left;
    }
}
.loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(220, 220, 220, 0.7);
    z-index: 1;
    overflow: hidden;
}

